
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$runple-web-primary: mat.define-palette(mat.$indigo-palette);
$runple-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$runple-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$runple-web-theme: mat.define-light-theme((
  color: (
    primary: $runple-web-primary,
    accent: $runple-web-accent,
    warn: $runple-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($runple-web-theme);

/**
 * @license
 * MyFonts Webfont Build ID 3922813, 2020-07-07T04:33:08-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
//@import url("//hello.myfonts.net/count/3bdb7d");

@import "src/assets/styles/variables";
@import "src/assets/styles/buttons";
@import "src/assets/styles/select";
@import "src/assets/styles/input";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('assets/fonts/Gilroy-Bold/font.woff2') format('woff2'), url('assets/fonts/Gilroy-Bold/font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy-Black';
  src:
    local('Gilroy-Black'),
    url('assets/fonts/Gilroy-Black/Gilroy-Black.otf') format('opentype');
  font-display: swap;
}

*, *::before, *::after { box-sizing: border-box; }
ul[class], ol[class] { padding: 0; list-style: none; }
body, h1, h2, h3, h4, p, ul[class], ol[class], li { margin: 0;}
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: $relative-size-16;
  line-height: 1.5em;
  letter-spacing: -$relative-size-02;
  color: #202431;
  position: relative;
}
input, button, textarea, select { font: inherit;}
a { text-decoration: none; color: inherit; font-weight: inherit}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


div:not(.ng-dropdown-panel-items)::-webkit-scrollbar,
.datatable-body::-webkit-scrollbar,
rnpl-view-nav::-webkit-scrollbar,
ul::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.dialog-body-container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
}

.dialog-body-container::-webkit-scrollbar-thumb {
  background-color: $color-grey-600;
  border-radius: 10px !important;
  border: 3px solid transparent;
  cursor: pointer !important;
  transition: .3s;

  &:hover {
    background-color: $color-grey-500;
    cursor: pointer;
  }
}

h1, h2, h3, h4, h5 {
  font-family: 'Gilroy-Bold', sans-serif;
  letter-spacing: -$relative-size-08;
  font-weight: 500;
}

.font-400 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.font-500, label {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.font-700 {
  font-family: 'Gilroy-Bold', sans-serif;
}

.ml-auto {
  margin-left: auto;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.pl-4 {
  padding-left: $relative-size-4;
}

button {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

html, body {
  width: 100%;
  max-width: 100vw !important;
  //max-height: 100vh !important;
  //overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
}

html {
  height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
}

.cdk-global-scrollblock {
  overflow-y: unset !important;
}

.mdc-dialog__container .mdc-dialog__surface {
  background-color: transparent !important;
  box-shadow: none !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(40px);
}

.modal-panel {
  width: 100%;
  max-width: $relative-size-616 !important;
  margin-top: 24px;
  max-height: calc(100% - 64px);
  overflow: auto;
}

mat-dialog-container {
  max-height: 100% !important;
}

.cdk-global-overlay-wrapper {
  align-items: flex-start !important;
}

.text-blue {
  color: #2D4AD3;
}

.text-violet {
  color: #7043CD;
}

.text-orange {
  color: #AF4C2C;
}

.w-full {
  width: 100% !important;
}

svg path {
  transition: fill .3s;
}

.social-icon {
  height: $relative-size-24;
  width: $relative-size-24;

  path {
    transition: .3s;
  }

  &:hover path {
    fill: $color-main-100
  }
}

.runple-logo {
  .main, .darked {
    transition: fill .3s;
  }

  &:hover {
    .main {
      fill: $color-blue;
    }
    .darked {
      fill: $color-blue-500;
    }
  }
}

.link.hover-blue:hover {
  color: $color-blue;

  path {
    fill: $color-blue;
  }
}

.br-0 {
  border-radius: 0 !important;
}

.break-spaces {
  white-space: break-spaces;
}

.dialog-container > .descr {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: $color-main;
}

.h-full {
  height: 100%;
}

.strong-title {
  font-family: 'Gilroy-Black', sans-serif;
  font-weight: 400;
}

@media (max-width: $desktop) {
  *, *::before, *::after { box-sizing: border-box; }
  ul[class], ol[class] { padding: 0; list-style: none; }
  body, h1, h2, h3, h4, p, ul[class], ol[class], li { margin: 0;}
  body {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }

  h1, h2, h3, h4, h5 {
    letter-spacing: -0.8px;
  }

  .pl-4 {
    padding-left: 4px;
  }

  .modal-panel {
    max-width: 616px !important;
    margin-top: 24px;
  }

  .social-icon {
    height: 24px !important;
    width: 24px !important;
  }

}

@media (max-width: $tablet) {
  .modal-panel {
    max-width: 100% !important;
    padding: 0 40px;
  }

  .tablet-none {
    display: none;
  }
}

@media (max-width: $mobile) {
  html {
    //overflow-y: auto !important;
    position: relative;
  }

  body {
    //max-height: 100% !important;
    //overflow-y: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .mobile-none {
    display: none;
  }

  .modal-panel {
    margin-top: 8px;
    padding: 0 8px;
    max-height: calc(100% - 16px);
  }
}
