@import "variables";

.ng-select {
  cursor: pointer !important;

  .ng-select-container {
    background-color: $color-main;
    border-radius: $relative-size-12;
    height: $relative-size-44;
    padding: 0 $relative-size-12;
    transition: background-color .3s;

    .ng-placeholder {
      margin-left: $relative-size-6;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
    }

    &.ng-has-value .ng-placeholder {
      display: none !important;
    }

    .ng-value-container {
      display: flex;
      align-items: center;

      .ng-value, .ng-placeholder {
        display: flex;
        align-items: center;
        font-size: $relative-size-14;
        line-height: $relative-size-20;
        letter-spacing: -$relative-size-02;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        img {
          height: $relative-size-24;
          width: $relative-size-24;
          margin-right: $relative-size-8;
        }
      }
    }

    .ng-clear-wrapper {
      display: none;
    }

    .ng-arrow-wrapper {
      display: flex;
      align-items: center;

      .ng-arrow {
        background-image: url("/assets/img/chevron-up.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: $relative-size-24;
        height: $relative-size-24;
        transform: rotate(-180deg);
        transition: .3s;
      }
    }

    &:hover {
      background-color: $color-main-500;
    }
  }

  &-opened {
    .ng-select-container {
      background-color: $color-main-400;

      .ng-arrow-wrapper {
        .ng-arrow {
          transform: rotate(0deg);
        }
      }

      &:hover {
        background-color: $color-main-400;
      }
    }
  }

  &.white {
    .ng-select-container {
      background-color: $color-white;
      border: $relative-size-2 solid $color-grey-500;
      transition: .3s;

      .ng-placeholder {
        margin-left: $relative-size-4;
        transition: color .3s;
      }

      .ng-value-container {
        .ng-value {
          color: $color-main;
          transition: color .3s;
        }

        .ng-placeholder {
          color: $color-main-100;
        }
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          background-image: url("/assets/img/chevron-up-main.svg");
        }
      }

      &:hover {
        background-color: $color-white;
        border-color: #CDD1DF;
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        background-color: $color-main-400;
        border-color: $color-main-400 !important;

        .ng-value-container {
          .ng-value {
            color: $color-white;
          }
        }

        &:hover {
          background-color: $color-main-400;
          border-color: $color-main-400;
        }

        .ng-arrow-wrapper {
          .ng-arrow {
            background-image: url("/assets/img/chevron-up.svg");
          }
        }
      }
    }
  }

  &.error .ng-select-container {
    border-color: #CF0B17 !important;
  }
}

.ng-dropdown-panel {
  background-color: $color-main-500;
  margin-top: $relative-size-4;
  border-radius: $relative-size-12;
  padding: $relative-size-4;

  &.ng-select-top {
    margin-top: 0;
    margin-bottom: $relative-size-4;
  }

  .ng-dropdown-panel-items {
    max-height: 600px !important;

    .ng-option {
      height: $relative-size-40;
      display: flex !important;
      align-items: center !important;
      margin-bottom: $relative-size-2;
      border-radius: $relative-size-12;
      padding: 0 $relative-size-8;
      transition: background-color .3s;
      font-size: $relative-size-16;
      line-height: $relative-size-24;
      letter-spacing: -$relative-size-02;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      color: $color-white;

      img.selected {
        display: none;
        margin-left: auto;
        margin-right: 0;
      }

      img {
        height: $relative-size-24;
        width: $relative-size-24;
        margin-right: $relative-size-8;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $color-main-400;
      }

      &-selected {
        img.selected {
          display: block;
        }
      }
    }
  }

  &.original-size {
    margin-top: 4px;
    border-radius: 12px;
    padding: 4px;

    .ng-dropdown-panel-items .ng-option {
      height: 40px;
      margin-bottom: 2px;
      border-radius: 12px;
      padding: 0 8px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;

      img {
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: $desktop) {
  .ng-select {
    .ng-select-container {
      border-radius: 12px;
      height: 44px;
      padding: 0 12px;

      .ng-placeholder {
        margin-left: 6px;
      }

      .ng-value-container {
        .ng-value, .ng-placeholder {
          font-size: 16px !important;
          line-height: 20px;
          letter-spacing: -0.2px;

          img {
            height: 24px;
            width: 24px;
            margin-right: 8px;
          }
        }
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.white {
      .ng-select-container {
        border: 2px solid $color-grey-500;

        .ng-placeholder {
          margin-left: 4px;
        }
      }
    }
  }

  .ng-dropdown-panel {
    margin-top: 4px;
    border-radius: 12px;
    padding: 4px;

    &.ng-select-top {
      margin-bottom: 4px;
    }

    .ng-dropdown-panel-items .ng-option {
      height: 40px;
      margin-bottom: 2px;
      border-radius: 12px;
      padding: 0 8px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;

      img {
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 240px !important;
  }
}

@media (max-height: 800px) {
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      max-height: 240px !important;
    }
  }
}
