@import "variables";

.btn {
  border-radius: $relative-size-16;
  height: $relative-size-64;
  padding: 0 $relative-size-20;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: 0;
  position: relative;
  justify-content: center;
  font-size: $relative-size-16;
  line-height: 1.5em;
  letter-spacing: -$relative-size-02;
  background-color: $color-blue;
  color: $color-white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  transition: background-color .3s, color .3s;

  .icon path {
    transition: .3s;
  }

  &[disabled], &.disabled {
    cursor: not-allowed;
  }

  &:hover:not(.disabled):not([disabled]) {
    background-color: $color-blue-500;
  }

  &:active:not(.disabled):not([disabled]) {
    background-color: $color-blue-600;
  }

  &-white {
    background-color: $color-white;
    color: $color-main;

    .icon path {
      fill: $color-main;
    }

    &[disabled], &.disabled {
      background-color: transparent;
    }

    &:hover:not(.disabled):not([disabled]) {
      background-color: $color-grey-500;

      .icon path {
        fill: $color-main;
      }
    }

    &:active:not(.disabled):not([disabled]) {
      background-color: $color-grey-600;

      .icon path {
        fill: $color-main;
      }
    }
  }

  &.reverse {
    img, svg {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  img, svg {
    height: $relative-size-24;
    width: $relative-size-24;
    margin-right: $relative-size-8;

    path {
      transition: .3s;
    }

    &.tablet {
      display: none;
    }
  }

  &-md {
    height: $relative-size-48;
    padding: $relative-size-12 $relative-size-16 $relative-size-12 $relative-size-12;
    border-radius: $relative-size-12;
    font-size: $relative-size-14;
    line-height: $relative-size-20;

    &.reverse {
      padding: $relative-size-12 $relative-size-12 $relative-size-12 $relative-size-16;

      img, svg {
        margin-right: 0;
        margin-left: $relative-size-8;
      }
    }
  }

  &-black {
    background-color: $color-main;

    &:hover:not(.disabled):not([disabled]) {
      background-color: $color-white;
      color: $color-black;

      .icon path {
        fill: $color-black;
      }
    }

    &:active:not(.disabled):not([disabled]) {
      background-color: $color-main-400;
    }
  }

  &-red {
    background-color: #CF0B17;
    color: $color-white;

    &:hover:not(.disabled):not([disabled]) {
      background-color: #B90915;
    }

    &:active:not(.disabled):not([disabled]) {
      background-color: #A41111;
    }
  }

  &-icon {
    padding: $relative-size-12;

    img {
      margin-right: 0;
    }
  }

  &-grey {
    background-color: $color-grey-400;
    color: $color-main;

    .icon path {
      fill: $color-main;
    }

    &:hover:not(.disabled):not([disabled]) {
      background-color: $color-grey-500;
    }

    &:active:not(.disabled):not([disabled]) {
      background-color: $color-grey-500;
    }
  }

  & > .link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $desktop) {
  .btn {
    border-radius: 16px;
    height: 64px;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: -0.2px;

    img, svg {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }

    &-md {
      height: 48px;
      padding: 12px 16px 12px 12px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 20px;

      &.reverse {
        padding: 12px 12px 12px 16px;

        img, svg {
          margin-left: 8px;
        }
      }
    }

    &-icon {
      padding: 12px;

      img {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: $tablet) {
  .btn img {
    &.desktop { display: none; }
    &.tablet { display: block; }
  }

  .btn-icon {
    background-color: transparent;
  }
}
