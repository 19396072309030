@import "variables";
.form-control {
  display: flex;
  flex-direction: column;
  position: relative;

  .icon {
    height: $relative-size-24;
    width: $relative-size-24;
  }

  app-form-valid-icon {
    display: none;
    position: absolute;
    bottom: $relative-size-10;
    right: $relative-size-10;
  }

  label {
    font-size: $relative-size-12;
    line-height: $relative-size-16;
    letter-spacing: -$relative-size-02;
    color: $color-main-200;
    margin-bottom: $relative-size-2;
    display: flex;
    justify-content: space-between;

    & > span {
      color: $color-main-100;
    }
  }

  input, textarea {
    padding: $relative-size-12 $relative-size-12 $relative-size-12 $relative-size-16;
    height: $relative-size-44;
    border: $relative-size-2 solid $color-grey-500;
    border-radius: $relative-size-12;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: $relative-size-14;
    line-height: $relative-size-20;
    outline-color: $color-main-400 !important;
    color: $color-main;
    box-shadow: none;
    background-color: $color-white;
    resize: vertical;

    &::placeholder {
      color: $color-main-100;
      font-weight: 400;
    }

    &:hover:enabled {
      border-color: #CDD1DF;
    }

    &:active:enabled, &:focus:enabled {
      border-color: $color-main !important;
      background-color: $color-main;
      color: $color-white;
      box-shadow: none;
      outline: none;
    }

    &:-webkit-autofill {
      -webkit-box-shadow:0 0 0 $relative-size-44 $color-white inset;
      -webkit-text-fill-color: $color-main;
      color: $color-main;
    }

    &:-webkit-autofill:focus, &:-webkit-autofill:active {
      -webkit-box-shadow:0 0 0 $relative-size-44 $color-main inset;
      -webkit-text-fill-color: $color-white;
      color: $color-white;
    }

    &.dark {
      background-color: $color-main-400;
      border-color: $color-main-400;
      color: $color-white;

      &:hover {
        border-color: $color-main-400;
      }

      &:active:enabled, &:focus:enabled, &:active, &:focus {
        border-color: $color-grey-500 !important;
        background-color: $color-white;
        color: $color-main;
      }

      &:-webkit-autofill {
        -webkit-box-shadow:0 0 0 $relative-size-44 $color-main-400 inset;
        -webkit-text-fill-color: $color-white;
        color: $color-white;
      }

      &:-webkit-autofill:focus, &:-webkit-autofill:active {
        -webkit-box-shadow:0 0 0 $relative-size-44 $color-white inset;
        -webkit-text-fill-color: $color-main;
        color: $color-main;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: $color-grey-200;
      color: $color-main-200;
      & + app-form-valid-icon .icon-valid path {
        fill: $color-green-500;
      }
    }

    &.ng-valid + app-form-valid-icon, &.valid + app-form-valid-icon {
      display: flex;
      align-items: center;
    }

    &.error {
      border-color: #CF0B17;

      &:hover:not(:active) {
        border-color: #CF0B17;
      }

      & ~.error-block {
        display: block;
      }
    }

    &::-ms-reveal {
      display: none;
    }
  }

  & > .password-toggle {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: $relative-size-10;
    right: $relative-size-10;
  }

  .error-block {
    display: none;
    position: absolute;
    text-align: left;
    bottom: -$relative-size-2;
    left: 0;
    transform: translateY(100%);
    color: #B90915;
    font-size: $relative-size-12;
    line-height: $relative-size-12;
  }
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: $relative-size-88;
}

@media (max-width: $desktop) {
  .form-control {
    .icon {
      height: 24px;
      width: 24px;
    }

    & > .password-toggle {
      bottom: 10px;
      right: 10px;
    }

    app-form-valid-icon {
      bottom: 10px;
      right: 10px;
    }

    label {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.2px;
      margin-bottom: 2px;
    }

    input, textarea {
      padding: 12px 12px 12px 16px;
      height: 44px;
      border: 2px solid $color-grey-500;
      border-radius: 12px;
      font-size: 14px;
      line-height: 20px;

      &:-webkit-autofill {
        -webkit-box-shadow:0 0 0 44px $color-white inset;
      }

      &:-webkit-autofill:focus, &:-webkit-autofill:active {
        -webkit-box-shadow:0 0 0 44px $color-main inset;
      }

      &.dark {
        &:-webkit-autofill {
          -webkit-box-shadow:0 0 0 44px $color-main-400 inset;
        }

        &:-webkit-autofill:focus, &:-webkit-autofill:active {
          -webkit-box-shadow:0 0 0 44px $color-white inset;
        }
      }
    }

    .error-block {
      font-size: 12px;
      line-height: 12px;
      bottom: -2px;
    }
  }

  textarea {
    min-height: 88px;
  }
}

@media (max-width: $mobile) {
  .form-control {
    input, textarea {
      font-size: 16px;
    }
  }
}
