$color-black: #000000;
$color-blue: #2F52F9;
$color-white: #FFFFFF;
$color-main: #202431;
$color-green: #6BCD50;

$color-main-100: #9CA4BE;
$color-main-200: #5E6681;
$color-main-300: #3E465F;
$color-main-400: #30364A;
$color-main-500: #282D3D;

$color-blue-500: #2440cd;
$color-blue-600: #1d35ae;

$color-grey-200: #F2F3F7;
$color-grey-400: #ECEEF3;
$color-grey-500: #E0E2EB;
$color-grey-600: #CDD1DF;

$color-green-500: #49A62F;
$color-green-600: #3F8E29;

//relative-size
$indexSize: .8;

$relative-size-02: calc(0.014vw * $indexSize);
$relative-size-04: calc(0.028vw * $indexSize);
$relative-size-05: calc(0.0347vw * $indexSize);
$relative-size-08: calc(0.056vw * $indexSize);
$relative-size-1: calc(0.07vw * $indexSize);
$relative-size-2: calc(0.14vw);
$relative-size-4: calc(0.278vw * $indexSize);
$relative-size-3: calc(0.209vw * $indexSize);
$relative-size-6: calc(0.417vw * $indexSize);
$relative-size-8: calc(0.556vw * $indexSize);
$relative-size-10: calc(0.695vw * $indexSize);
$relative-size-12: calc(0.834vw * $indexSize);
$relative-size-14: calc(0.973vw * $indexSize);
$relative-size-15: calc(1.042vw * $indexSize);
$relative-size-16: calc(1.112vw * $indexSize);
$relative-size-17: calc(1.181vw * $indexSize);
$relative-size-18: calc(1.25vw * $indexSize);
$relative-size-20: calc(1.528vw * $indexSize);
$relative-size-22: calc(1.389vw * $indexSize);
$relative-size-24: calc(1.667vw * $indexSize);
$relative-size-26: calc(1.806vw * $indexSize);
$relative-size-28: calc(1.945vw * $indexSize);
$relative-size-30: calc(2.084vw * $indexSize);
$relative-size-32: calc(2.223vw * $indexSize);
$relative-size-36: calc(2.5vw * $indexSize);
$relative-size-38: calc(2.639vw * $indexSize);
$relative-size-40: calc(2.778vw * $indexSize);
$relative-size-44: calc(3.056vw * $indexSize);
$relative-size-48: calc(3.334vw * $indexSize);
$relative-size-50: calc(3.473vw * $indexSize);
$relative-size-52: calc(3.612vw * $indexSize);
$relative-size-56: calc(3.889vw * $indexSize);
$relative-size-60: calc(4.167vw * $indexSize);
$relative-size-64: calc(4.445vw * $indexSize);
$relative-size-68: calc(4.723vw * $indexSize);
$relative-size-70: calc(4.862vw * $indexSize);
$relative-size-72: calc(5vw * $indexSize);
$relative-size-80: calc(5.556vw * $indexSize);
$relative-size-88: calc(6.112vw * $indexSize);
$relative-size-92: calc(6.389vw * $indexSize);
$relative-size-96: calc(6.667vw * $indexSize);
$relative-size-100: calc(6.945vw * $indexSize);
$relative-size-108: calc(7.5vw * $indexSize);
$relative-size-130: calc(9.028vw * $indexSize);
$relative-size-144: calc(10vw * $indexSize);
$relative-size-150: calc(10.417vw * $indexSize);
$relative-size-158: calc(10.973vw * $indexSize);
$relative-size-163: calc(11.32vw * $indexSize);
$relative-size-178: calc(12.362vw * $indexSize);
$relative-size-189: calc(13.125vw * $indexSize);
$relative-size-200: calc(13.889vw * $indexSize);
$relative-size-220: calc(15.278vw * $indexSize);
$relative-size-238: calc(16.528vw * $indexSize);
$relative-size-246: calc(17.084vw * $indexSize);
$relative-size-254: calc(17.639vw * $indexSize);
$relative-size-258: calc(17.917vw * $indexSize);
$relative-size-260: calc(18.056vw * $indexSize);
$relative-size-266: calc(18.473vw * $indexSize);
$relative-size-272: calc(18.889vw * $indexSize);
$relative-size-300: calc(20.834vw * $indexSize);
$relative-size-308: calc(21.39vw * $indexSize);
$relative-size-310: calc(21.528vw * $indexSize);
$relative-size-320: calc(22.223vw * $indexSize);
$relative-size-342: calc(23.75vw * $indexSize);
$relative-size-356: calc(24.723vw * $indexSize);
$relative-size-360: calc(25vw * $indexSize);
$relative-size-388: calc(26.945vw * $indexSize);
$relative-size-400: calc(27.778vw * $indexSize);
$relative-size-406: calc(28.195vw * $indexSize);
$relative-size-411: calc(28.3vw * $indexSize);
$relative-size-432: calc(30vw * $indexSize);
$relative-size-450: calc(31.25vw * $indexSize);
$relative-size-480: calc(33.334vw * $indexSize);
$relative-size-488: calc(33.889vw * $indexSize);
$relative-size-500: calc(34.723vw * $indexSize);
$relative-size-600: calc(41.667vw * $indexSize);
$relative-size-616: calc(42.778vw * $indexSize);
$relative-size-620: calc(43.056vw * $indexSize);
$relative-size-624: calc(43.334vw * $indexSize);
$relative-size-628: calc(43.612vw * $indexSize);
$relative-size-638: calc(44.306vw * $indexSize);
$relative-size-646: calc(44.862vw * $indexSize);
$relative-size-676: calc(46.945vw * $indexSize);
$relative-size-720: calc(50vw * $indexSize);
$relative-size-784: calc(54.445vw * $indexSize);
$relative-size-800: calc(55.556vw * $indexSize);
$relative-size-802: calc(55.695vw * $indexSize);
$relative-size-834: calc(57.917vw * $indexSize);
$relative-size-920: calc(63.89vw * $indexSize);
$relative-size-992: calc(68.889vw * $indexSize);
$relative-size-1084: calc(75.278vw * $indexSize);
$relative-size-1156: calc(80.278vw * $indexSize);
$relative-size-1272: calc(88.334vw * $indexSize);

//breakpoints
$mobile: 481px;
$tablet: 991px;
$desktop: 1800px;
$desktopSmall: 1280px;
